import { useState } from "react";
import { useAuthFetch } from '.././useAuthFetch'
import { getApiUrl } from '.././authConfig';

export async function requestUsers(authFetch: any) {
    let fetchResponse = await authFetch('GET', getApiUrl(`users`))
    if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
        let responseData;
        try {
            responseData = await fetchResponse.json();
        } catch (error) {
            console.log('Request User Error: ' + error);
        } finally {
            return responseData;
        }
    }
}