import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react'
import { CreateBuildingDialog } from '../CreateBuildingDialog'
import { useAuthFetch } from '../useAuthFetch'
import { LoadingTemplate } from '../LoadingTemplate';
import { getApiUrl } from '../authConfig';
import { useNavigate } from 'react-router-dom';
import { toJS } from 'mobx';

import { requestBuildingData } from "../hooks/Building";
import { requestUserGridConfigs } from "../hooks/UsersGridConfigs";

import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { SideBarDef, } from "ag-grid-community";

import Button from 'react-bootstrap/Button';
import "../assets/css/formItems.css";
import Form from 'react-bootstrap/Form';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { UserGridConfigObject } from "../common/types";

import Modal from 'react-bootstrap/Modal';

export function BuildingsPage() {
    const gridRef = useRef<AgGridReact>(null);
    const navigate = useNavigate();
    const authFetch = useAuthFetch(); 

    const [ buildingData, setBuildingData] = useState([]);
    const [ userGridConfigsData, setUserGridConfigsData] = useState<UserGridConfigObject[]>([]);
    const [ dialogOpen, setDialogOpen ] = useState(false);
    const [ buildingSuccess, setBuildingSuccess] = useState<string | null>(null);
    const [ modalGridConfigOpen, setModalGridConfigOpen] = useState(false); 
    const [ validated, setValidated] = useState(false);
    const [ gridConfigDescription, setGridConfigDescription] = useState(null);

    const requestInitialData = async () => {
        await requestBuildingData(authFetch, "").then(res => { return res; }).then((data) => { setBuildingData(data); });
    }

    const requestUserConfigData = async () => {
        await requestUserGridConfigs(authFetch, "building").then(res => { return res; }).then((data) => { setUserGridConfigsData(data); });
    }

    useEffect(() => {
        requestInitialData();
        requestUserConfigData();
    }, []);

    const handleDialogOpen = () => {
        setDialogOpen(true); 
    }

    const handleDialogSubmit = (status: string) => {
        if(status != "cancel") {
            requestInitialData(); 
        }
        setDialogOpen(false);
    }

    const CustomButtonComponent = (props) => {
        return (
            <div style={{ marginLeft: "-10px" }}>
                <Button type="button" className="btn btn-primary btn-sm" onClick={(ev) => {
                    ev.stopPropagation();
                    navigate(`/assets/${props.data.id}`)
                }}>
                    Assets
                    &nbsp;&nbsp;<i className="fa fa-bolt" />
                </Button>
            </div>
        )
    };

    const [colDefs, setColDefs] = useState([
        { headerName: "Building Name", field: "name" },
        { headerName: "Street Address", field: "address" },
        { field: "city", enableRowGroup: true },
        { field: "province", enableRowGroup: true },
        { field: "status", enableRowGroup: true },
        { field: "Actions", maxWidth: 125, cellRenderer: CustomButtonComponent }
    ]);

    const onFilterTextBoxChanged = useCallback(() => {
        gridRef.current!.api.setGridOption(
            "quickFilterText",
            (document.getElementById("filter-text-box") as HTMLInputElement).value,
        );
    }, []);

    const statusBar = useMemo(() => {
        return {
            statusPanels: [
                { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
                { statusPanel: 'agFilteredRowCountComponent', align: 'left' }
            ]
        };
    }, []);

    const sideBar = useMemo<
        SideBarDef | string | string[] | boolean | null
    >(() => {
        return {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivots: true,
                        suppressPivotMode: true,
                        suppressColumnFilter: true,
                        suppressColumnSelectAll: true,
                        suppressColumnExpandAll: true,
                    },
                },
            ],
            defaultToolPanel: "",
        };
    }, []);

    const buildingAdded = (value: string) => { toast(value); };

    useEffect(() => { buildingAdded(buildingSuccess); }, [buildingSuccess]);

    const onCsvExport = useCallback(() => {
        gridRef.current!.api.exportDataAsCsv();
    }, []);

    const onExcelExport = useCallback(() => {
        gridRef.current!.api.exportDataAsExcel();
    }, []);

    const handleSubmit = async (event) => {
        let userGridConfig = {} as UserGridConfigObject;
        userGridConfig.description = gridConfigDescription;
        userGridConfig.gridName = "building";
        userGridConfig.columnSpec = gridRef.current!.api.getColumnState();

        let data = toJS(userGridConfig);
        let fetchResponse = await authFetch("POST", getApiUrl("usergridconfigs"), { ...data });
        if (fetchResponse.status === 406) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else if (fetchResponse.status != 409) {
            console.log("Added");
            setModalGridConfigOpen(false);
            requestUserConfigData();
        } else {
            console.log("Error: " + fetchResponse.status);
        }
    }

    const addPageSetting = (event) => {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            handleSubmit(false);
            event.preventDefault();
        }
    }

    const userConfigChange = (event) => {
        let userGridConfigSelected = userGridConfigsData.find(x => x.id === event.target.value);
        gridRef.current!.api.applyColumnState({
            state: userGridConfigSelected.columnSpec,
            applyOrder: true,
        });
    }
 
    return (
        <>
            <ToastContainer />
            <LoadingTemplate isLoading={ buildingData.length == 0}>
                <div>
                    <Button variant="primary" className="rounded-circle modalAddButton" onClick={handleDialogOpen}>+</Button>
                    <div className="titleRow">
                        <div style={{ flexGrow: '1' }}>Buildings</div>
                        <i className="fa-solid fa-gear clickable hoverable" onClick={() => { setModalGridConfigOpen(true) }}></i>
                    </div>

                    <div className="row mb-2">
                        <div className="col col-8 col-lg-3 col-sm-6">
                            <input type="text" id="filter-text-box" className="form-control" placeholder="Filter..." onInput={onFilterTextBoxChanged} />
                        </div>
                        <div style={{ float: "left", width: "8%" }} >
                            <i className="fa-solid fa-file-excel fa-2x me-2 clickable hoverable" onClick={onExcelExport} style={{ lineHeight: '1.25em' }}></i>
                            <i className="fa-solid fa-file-csv fa-2x clickable hoverable" onClick={onCsvExport}></i>
                        </div>
                    </div>

                    <div className="ag-theme-quartz" style={{ height: 700 }}>
                        <AgGridReact ref={gridRef} rowData={buildingData} statusBar={statusBar} sideBar={sideBar} columnDefs={colDefs} rowGroupPanelShow={"always"} rowStyle={{ cursor: "pointer" }} onRowClicked={(e) =>
                            navigate(`/building/${e.data.id}`)
                        } />
                    </div>

                    <CreateBuildingDialog isOpen={dialogOpen} onSubmit={handleDialogSubmit} buildingSuccess={(successStatus) => setBuildingSuccess(successStatus)} />

                    <Modal className="modalStyle" show={modalGridConfigOpen} centered size="lg">
                        <Modal.Header>
                            <Modal.Title>Grid Configuration</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ overflow: "hidden" }}>
                                <div style={{ width: "48%", float: "left"}}>
                                    <div>
                                        <div>Load a Grid Configuration</div>
                                        <Form.Select required className={"d-grid"} size="lg" style={{ width: "300px", float: "left" }} onChange={userConfigChange}>
                                            <option key={0} value="" selected disabled></option>
                                            {userGridConfigsData.map((value, index) => {
                                                return (<option key={index} value={value.id}>{value.description}</option>);
                                            })}
                                        </Form.Select>
                                    </div>
                                </div>
                                <div style={{ width: "48%", overflow: "hidden" }}>
                                    <div>Save The Current Grid Configuration</div>
                                    <Form noValidate validated={validated} onSubmit={addPageSetting}>
                                        <Form.Group controlId="username">
                                            <Form.Control required className="modal-input" size="lg" type="text" placeholder="Description Name" style={{ width: "300px", float: "left" }} onChange={e => setGridConfigDescription(e.target.value)} />
                                            <button className="btn btn-link" type="submit"><i className="fa-solid fa-save fa-2x clickable hoverable"></i></button>
                                            <Form.Control.Feedback type="invalid">
                                                Descirption cannot be Empty
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => { setModalGridConfigOpen(false) }}>Close</Button>
                        </Modal.Footer>
                    </Modal>

                </div>
            </LoadingTemplate>
        </>
    );
};

