import React from "react";
import { useAuthFetch } from './useAuthFetch';
import { observer, useLocalObservable } from 'mobx-react';
import { getApiUrl } from "./authConfig";

import "./assets/css/modal.css";

import { InspectionObject } from "./common/types";
import { AssetObject } from "./common/types";

var indent = 0;
var parentId = "";

function formatDuration(duration) {
    const hrs = ~~(duration / 3600);
    const mins = ~~((duration % 3600) / 60);
    const secs = ~~duration % 60;
    let ret = "";

    if (hrs > 0) {
        ret += "" + hrs + "h " + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + "m " + (secs < 10 ? "0" : "");
    ret += "" + secs + "s";

    return ret;
}

export type InspectionPreviewProps = {
    assetData?: AssetObject,
    inspectionData?: InspectionObject,
    pdfDownload?: string
}

export const InspectionPreview = observer((props: InspectionPreviewProps) => {
    const authFetch = useAuthFetch();

    const formInspectionData = useLocalObservable(() => ({
        creatorName: "",
        completionDate: "",
        creationDate: "",
        answers: [],
        template: null,
        duration: null
    }))

    var assetObject;
    var inspectionObject;
    var isPdf;

    assetObject = props.assetData;
    inspectionObject = props.inspectionData;
    isPdf = props.pdfDownload;

    let pageHeaderFontSize = "22px";
    let pageHeaderImageWidth = "30px";
    let pageHeaderRibbonHeight = "50px";
    let ribbonFontSize = "16px";
    let ribbonHeight = "30px";
    let ribbonMarginLeft = "30px";
    let addressContainer = "22px";
    let logoImage = "120px";
    let addressLeft = "20px";
    let addressTop = "12px";
    let addressFontSize = "14px";
    let addressWidth = "150px";
    let bodyPadding = "20px 24px";
    let ribbonTop = "20px";
    let assetHeaderText = "22px";
    let assetHeaderWidth = "300px";
    let assetHeaderImage = "22px";
    let assetImageRight = "25px";
    let tableFontSize = "12px";
    let tablewidth = "100%";
    let tableRightWidth = "300px";
    let tablePadding = "10px";
    let questionIndent = 40;
    let qaContainer = "20px"
    let qaFontSize = "14px";
    let qaMarginTop = "10px";


    if (isPdf == "true") {
        pageHeaderFontSize = "10px";
        pageHeaderImageWidth = "16px";
        pageHeaderRibbonHeight = "20px";
        ribbonFontSize = "5px";
        ribbonHeight = "10px";
        ribbonMarginLeft = "10px";
        addressContainer = "22px";
        logoImage = "40px";
        addressLeft = "5px";
        addressTop = "6px";
        addressFontSize = "5px";
        addressWidth = "60px";
        bodyPadding = "8px 12px";
        ribbonTop = "8px";
        assetHeaderText = "8px";
        assetHeaderWidth = "100px";
        assetHeaderImage = "12px";
        assetImageRight = "15px";
        tablewidth = "97.8%"
        tableRightWidth = "260px"
        tableFontSize = "4px";
        tablePadding = "2px";
        questionIndent = 10;
        qaContainer = "16px"
        qaFontSize = "5px";
        qaMarginTop = "0px";
    }

    const assetTypeImage = (assetType: string) => {
        switch (assetType) {
            case "Thermal":
                return require("./assets/images/thermal-icon.png")
            case "Gas":
                return require("./assets/images/gas-icon.png")
            case "Electricity":
                return require("./assets/images/electricity-icon.png")
            case "Communication":
                return require("./assets/images/communication-icon.png")
            case "Steam":
                return require("./assets/images/steam-icon.png")
            default:
                return require("./assets/images/water-icon.png")
        }
    };

    const downloadImage = async (photo) => {
        let fetchResponse = await authFetch('GET', getApiUrl(`files/${photo}/download`)).then(response => response.blob())
            .then(blob => {
                const imageUrl = URL.createObjectURL(blob);
                if (document.getElementById('img-' + photo)) {
                    document.getElementById('img-' + photo).setAttribute('src', imageUrl);
                }
            })
            .catch(error => {
                console.error('Error downloading the file:', error);
            });
    }

    const checkAnswerColor = (answer: string) => {
        switch (answer) {
            case "N/A":
                return "#bbb";
            case "Complete":
            case "Pass":
                return "#00cc66";
            default:
                return "#3366cc";
        }
    };

    return (
        <div style={{ fontFamily: "Verdana, sans-serif" }}>
            <div style={{display: 'flex', flexDirection: 'row', marginTop: '8px', marginBottom: '18px', marginLeft: '8px', fontWeight:'bold', color: '#666', alignItems: 'center'}} >
                <img style={{ width: logoImage, float: 'left' }} src={require("./assets/images/logo.png")}></img>
                <div style={{ marginLeft: addressLeft, marginTop: addressTop, fontSize: addressFontSize, width: addressWidth, float: 'left' }}>
                    <span style={{ float: 'left'}}>573 Sherling Place</span>
                    <span style={{ float: 'left'}}>Port Coquitlam</span>
                    <span style={{ float: 'left'}}>V3B 0J6</span>
                </div>
            </div>

            <div style={{ float: 'left', backgroundColor: "#3366cc", width: "100%", marginTop: "1%", height: pageHeaderRibbonHeight, lineHeight: pageHeaderRibbonHeight }}>
                <img style={{ width: pageHeaderImageWidth, float: 'left', marginLeft: '1%', marginTop: '1%', marginBottom: '1%' }} src={require("./assets/images/inspection-icon.png")}></img>
                <div style={{ color: "white", textAlign: "center", fontSize: pageHeaderFontSize, fontWeight: "bold" }}>INSPECTION REPORT</div>
            </div>

            <div style={{ overflow: 'auto', padding: bodyPadding }} >
                <div style={{ color: '#e35205', height: assetHeaderImage }}>
                    <div style={{ float: 'left', fontSize: assetHeaderText, fontWeight: 'bold', height: assetHeaderImage }}>
                        <div style={{ height: assetHeaderImage, lineHeight: assetHeaderImage }}>{assetObject == null ? "" : assetObject.manufacturer} - {assetObject == null ? "" : assetObject.model} | {assetObject == null ? "" : assetObject.serialNumber}</div>
                    </div>

                    <div style={{ float: 'right', height: assetHeaderImage, overflow: "hidden" }}>
                        <div style={{ float: 'left', marginRight: assetImageRight, height: assetHeaderImage }}><img style={{ position: "absolute", height: assetHeaderImage }} src={assetObject == null ? "" : assetTypeImage(assetObject.assetType)}></img></div>
                        <div style={{ overflow: "hidden", fontSize: assetHeaderText, fontWeight: 'bold', height: assetHeaderImage, lineHeight: assetHeaderImage }}>{assetObject == null ? "" : assetObject.assetType}</div>
                    </div>
                    <div style={{ clear: "both" }}></div>
                </div>

                <div style={{ backgroundColor: "#3366cc", height: ribbonHeight, width: "100%", marginTop: ribbonTop }}>
                    <div style={{ color: "white", marginLeft: ribbonMarginLeft, lineHeight: ribbonHeight, fontSize: ribbonFontSize, fontWeight: "bold" }}>OVERVIEW</div>
                </div>

                <div style={{ border: "solid 1px", borderColor: "#3366cc", padding: tablePadding, float: "left", width: tablewidth, fontSize: tableFontSize } }>

                    <div style={{ marginTop: "4px", width: "52%", float: "left" }}>
                        <span style={{ float: "left", marginRight: '5px', fontWeight: "bold" }}>Procedure:</span>{inspectionObject?.template?.name} (v{inspectionObject?.template?.version})<br />
                        <span style={{ float: "left", marginRight: '5px', fontWeight: "bold" }}>Technician:</span>{inspectionObject?.creatorName}<br />
                        <span style={{ float: "left", marginRight: '5px', fontWeight: "bold" }}>Building/Project:</span>{assetObject == null || assetObject.building == null ? "" : assetObject.building.name}
                    </div>

                    <div style={{ marginTop: "4px", width: "48%", display: "inline-block" }}>
                        <span style={{ float: 'left', marginRight: '5px', fontWeight: "bold" }}>Completed: </span>{new Date(inspectionObject?.completionDate).toLocaleString()}<br />
                        <span style={{ float: 'left', marginRight: '5px', fontWeight: "bold" }}>Uploaded: </span>{new Date(inspectionObject?.creationDate).toLocaleString()}<br />
                        <span style={{ float: 'left', marginRight: '5px', fontWeight: "bold" }}>Duration: </span>{formatDuration(inspectionObject?.duration)}
                    </div>

                </div>

                <div style={{ backgroundColor: "#3366cc", height: ribbonHeight, width: "100%", float: 'left', marginTop: ribbonTop }}>
                    <div style={{ color: "white", marginLeft: ribbonMarginLeft, lineHeight: ribbonHeight, fontSize: ribbonFontSize, fontWeight: "bold" }}>DETAILS</div>
                </div>

                {
                    inspectionObject?.answers?.map((answer, idx) =>
                        <>
                            {(() => {
                                if (answer.parentQuestionId == "") {
                                    parentId = "";
                                    indent = 0;
                                    return (

                                        <>
                                            {idx != 0 ? <div id={`qa-${idx}`} key={idx} style={{ width: "100%", float: "left", marginTop: '2px', borderBottom: "1px solid #36c" }} /> : <div id={`qa-${idx}`} key={idx}>&nbsp;</div>}
                                            <div style={{ float: "left", width: "100%", marginTop: qaMarginTop, marginLeft: indent } as React.CSSProperties}>
                                                <div style={{height: qaContainer}}>
                                                    <span style={{ fontSize: qaFontSize }}>{answer.questionText == null || answer.questionText == "" ? answer.questionId : answer.questionText}</span>
                                                    <span style={{ fontSize: qaFontSize, padding: "4px 10px 4px 10px", color: `${checkAnswerColor(answer.response)}` }}>{answer.response}</span>
                                                </div>
                                                {answer.photoContent != null ? answer.photoContent.map((photo, pidx) => {
                                                        return <><img id={`img-${photo.photoId}`} key={pidx} className="mb-2 me-2" style={{ maxHeight: "400px", maxWidth: "400px" }} src={"data:" + photo.mimeType +";base64," + photo.base64Content} /></>
                                                    }) : null
                                                }
                                            </div>
                                        </>
                                    )
                                } else {
                                    if (answer.parentQuestionId != parentId) { indent = indent + questionIndent; }

                                    parentId = answer.parentQuestionId;
                                    return (
                                        <div key={idx} style={{ float: "left", width: "100%", marginTop: qaMarginTop, marginLeft: indent } as React.CSSProperties}>
                                            <div style={{ height: qaContainer }}>
                                                <span style={{ fontSize: qaFontSize }}>{answer.questionText == null || answer.questionText == "" ? answer.questionId : answer.questionText}</span>
                                                <span style={{ fontSize: qaFontSize, padding: "4px 10px 4px 10px", color: `${checkAnswerColor(answer.response)}` }}>{answer.response}</span>
                                            </div>
                                            {answer.photoContent != null ? answer.photoContent.map((photo, pidx) => {
                                                return <><img id={`img-${photo.photoId}`} key={pidx} className="mb-2 me-2" style={{ maxHeight: "400px", maxWidth: "400px" }} src={"data:" + photo.mimeType + ";base64," + photo.base64Content} /></>
                                            }) : null
                                            }
                                        </div>
                                    )

                                }
                            })()}
                        </>
                    )}

            </div>
        </div>

    );
});
