export const AssetStatuses = ["Not Commissioned", "Decommissioned", "Pass", "Fail"];
export const DeploymentPhases = ["Warehouse", "Vendor", "Contractor", "Building"];
export const CommissioningItems = ["No Commissioning", "Commissioning", "SE-04"];
export const BuildingStatuses = ["Active", "Commissioning", "Predeployment", "Preinstallation", "Inactive", "Demo"];
export const MeterVoltageDrop = ["120", "208", "240", "277", "347", "480", "600"];
export const MeterCurrentDrop = ["0.08", "0.1", "5"];
export const DemandTypeDrop = ["N/A", "Block", "Rolling block / Sliding Window", "Exponential", "Other"];
export const PulseOutputTypeDrop = ["N/A", "Form A/KY/KZ (2-wire)", "Form C/KYZ (3-wire)", "Solid state (digital)", "Other"];
export const NumberElementsDrop = ["1", "1.5", "2", "3", "Auto"];
export const Country = [
    {
        "id": 39,
        "name": "Canada",
        "iso3": "CAN",
        "iso2": "CA",
        "numeric_code": "124",
        "phone_code": "1",
        "capital": "Ottawa",
        "currency": "CAD",
        "currency_name": "Canadian dollar",
        "native": "Canada",
        "region": "Americas",
        "subregion": "Northern America",
        "latitude": "60.00000000",
        "longitude": "-95.00000000",
    },
    {
        "id": 233,
        "name": "USA",
        "iso3": "USA",
        "iso2": "US",
        "numeric_code": "840",
        "phone_code": "1",
        "capital": "Washington",
        "currency": "USD",
        "currency_name": "United States dollar",
        "native": "United States",
        "region": "Americas",
        "subregion": "Northern America",
        "latitude": "38.00000000",
        "longitude": "-97.00000000",
    }
];
export const MeterConfigurationDrop = [
    { value: '1', name: 'Single Phase' },
    { value: '2', name: '2 Phase Network' },
    { value: '3', name: '3 Phase Delta' },
    { value: '4', name: '3 Phase' }
];
export const InspectionTypeDrop = [
    { value: '1', name: 'Initial' },
    { value: '2', name: 'Reverification' }
];
export const SealParametersDrop = [
    { name: 'Wh' },
    { name: 'W' },
    { name: 'Rec. Wh' },
    { name: 'Pulse' },
    { name: 'VAh (rms)' },
    { name: 'VA (rms)' },
    { name: 'Rec.VAh (rms)' },
    { name: 'VARh' },
    { name: 'VAR' },
    { name: 'Rec. VARh' }
];