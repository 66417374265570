import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { useAuthFetch } from './useAuthFetch';

import { requestUsers } from "./hooks/Users";

export function LoginPage() {
    const { instance, accounts } = useMsal();
    const authFetch = useAuthFetch();

    const userApi = async () => {
        await requestUsers(authFetch);
    }

    const handleLogin = () => {
        instance.loginRedirect(loginRequest).then(e => { userApi(); }).catch(e => {
            console.log("Handle Login Error");
        });
    };

    return (
        <div>
            <div className="h-100 d-flex align-items-center justify-content-center pt-5">
                <div className="">
                    <p>You are not logged into MosaiQ</p>
                    <button type="button" className="btn p-2 m-2 bg-secondary w-100 text-white shadow text-center" onClick={handleLogin} style={{ cursor: 'pointer' }} >
                        Login
                    </button>
                </div>
            </div>
        </div>
    );
};

