import React, { Children, ReactNode } from 'react'

type LoadingTemplateProps = {
    isLoading: boolean
    fallback?: ReactNode
    children?: ReactNode
}

export function LoadingTemplate(props: LoadingTemplateProps) {

    if(props.isLoading) {

        if(props.fallback == null) {
            return (
                <div style={{ display: "flex"}}>
                    <div className="spinner-border text-primary" role="status"></div>
                </div>
            )
        }

        return props.fallback; 
    }

    return props.children; 
}
