import React, { useEffect } from 'react'
import { useState } from "react";
import { CreateBuildingDialog } from '../CreateBuildingDialog'
import { useAuthFetch } from '../useAuthFetch'
import { LoadingTemplate } from '../LoadingTemplate';
import { getApiUrl } from '../authConfig';
import { AuditLogPopup } from '../AuditLogPopup';
import { useNavigate, useParams } from 'react-router-dom';
import FileUpload from '../FileDrop'

import { requestBuildingData } from "../hooks/Building";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const faIconMap = {
    pdf: "fa-solid fa-file-pdf",
    doc: "fa-solid fa-file-word",
    docx: "fa-solid fa-file-word",
    xls: "fa-solid fa-file-excel",
    xlsx: "fa-solid fa-file-excel",
    csv: "fa-solid fa-file-csv",
    dwg: "fa-solid fa-compass-drafting",
    xml: "fa-solid fa-code",
    json: "fa-solid fa-code",
    jpg: "fa-solid fa-file-image",
    jpeg: "fa-solid fa-file-image",
    png: "fa-solid fa-file-image",
    bmp: "fa-solid fa-file-image",
    gif: "fa-solid fa-file-image",
    heif: "fa-solid fa-file-image"
}

const faFile = 'fa-solid fa-file';
export function BuildingDetailsPage() {
    const params = useParams();
    const authFetch = useAuthFetch();
    const buildingId = params.id;

    const [ loading, setLoading ] = useState<boolean>(true); 
    const [ buildingData, setBuildingData ] = useState(null);
    const [ auditLogData, setAuditLogData ] = useState([]); 
    const [ noteText, setNoteText ] = useState("");
    const [ editDialogOpen, setEditDialogOpen ] = useState<boolean>(false);
    const [ logPopupOpen, setLogPopupOpen ] = useState<boolean>(false);
    const [ fileData, setFileData ] = useState([]);
    const [ assetSummaryData, setAssetSummaryData] = useState(null);
    const [ buildingSuccess, setBuildingSuccess] = useState<string | null>(null);

    const requestNotesData = async () => {
        setNoteText("")
        let fetchResponse = await authFetch('GET', getApiUrl(`notes/buildings/${buildingId}`))
        if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
            let responseData = {} as any;

            try {
                responseData = await fetchResponse.json();
            } catch (error) {
                console.log('Request Notes Data Error: ' + error);
            } finally {
                setNoteText(responseData.noteText || "");
            }
        }
    }

    const requestFileData = async () => {
        setFileData([])
        let fetchResponse = await authFetch('GET', getApiUrl(`files/buildings/${buildingId}`))
        if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
            let responseData = [];

            try {
                responseData = await fetchResponse.json();
            } catch (error) {
                console.log('Request File Data Error: ' + error);
            } finally {
                setFileData(responseData.reverse());
            }
        }
    }

    const requestAssetSummaryData = async () => {
        setAssetSummaryData(null)
        let fetchResponse = await authFetch('GET', getApiUrl(`assets/building/${buildingId}/summary`))
        if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
            let responseData = [];

            try {
                responseData = await fetchResponse.json();
            } catch (error) {
                console.log('Request File Data Error: ' + error);
            } finally {
                setAssetSummaryData(responseData);
            }
        }
    }


    const requestAuditLogData = async () => {
        setAuditLogData([])
        let fetchResponse = await authFetch('GET', getApiUrl(`auditLog/buildings/${buildingId}`))
        if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
            let responseData = [];

            try {
                responseData = await fetchResponse.json();
            } catch (error) {
                console.log('Request Audit Log Data Error: ' + error);
            } finally {
                setAuditLogData(responseData.reverse());
            }
        }
    }

    const requestData = ()=> {
        let tasks = [
            requestBuildingData(authFetch, buildingId).then(res => { return res; }).then((data) => { setBuildingData(data); }), 
            requestNotesData(),
            requestAuditLogData(),
            requestFileData(),
            requestAssetSummaryData()
        ];
        Promise.all(tasks).then(()=> setLoading(false));
    }

    const submitNotesData = async () => {
        let fetchResponse = await authFetch('POST', getApiUrl(`notes/buildings/${buildingId}`), {
            noteText: noteText
        })
        await requestAuditLogData()
    }
    
    const handleEditClick = async () =>  {
        setEditDialogOpen(true); 
    }

    const handleEditDialogSubmit = (status: string) => {
        if(status != "cancel") {
            requestData(); 
        }
        setEditDialogOpen(false);
    }

    const handleLogPopupOpen = () => {
        setLogPopupOpen(true); 
    }

    const handleLogPopupClose = () => {
        setLogPopupOpen(false); 
    }

    useEffect(requestData, []);

    const downloadFile = async (fileItem) => {
        let fetchResponse = await authFetch('GET', getApiUrl(`files/${fileItem.id}/download`)).then(response => response.blob())
            .then(blob => {
                const downloadUrl = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = downloadUrl;
                a.download = fileItem.fileName; // Optional: Set a default file name
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(downloadUrl); // Cleanup
            })
            .catch(error => {
                console.error('Error downloading the file:', error);
            });
    };

    function getBuildingStatusBadge(status = "") {
      //  ["Active", "Commissioning", "Predeployment", "Preinstallation", "Inactive", "Demo"];
        switch (status.toLowerCase()) {
            case "predeployment":
                return <span className="badge bg-warning float-end">Pre-Deployment</span>
            case "commissioning":
                return <span className="badge bg-warning float-end">Commmissioning</span>
                break;
            case "preinstallation":
                return <span className="badge bg-warning float-end">Pre-Installation</span>
                break;
            case "inactive":
                return <span className="badge bg-danger float-end">Inactive</span>
                break;
            case "active":
                return <span className="badge bg-success float-end">Active</span>
                break;
            default:
                return <span className="badge bg-info float-end">{status}</span>
        }
    }

    const buildingAdded = (value: string) => { toast(value); };

    useEffect(() => { buildingAdded(buildingSuccess); }, [buildingSuccess]);


    return (
    <>
        <ToastContainer />
        <LoadingTemplate isLoading={loading}>
            <div>
                <h1>{buildingData?.name}</h1>
                <div className="row">
                    <div className="col col-12 col-lg-6 col-xl-4">
                        <div className="card">
                            {/*TODO handle edit click*/}
                            <div className="card-title card-title-collapsable">
                                Building Info
                            </div>
                            <div className="card-body">
                                {getBuildingStatusBadge(buildingData?.status)}

                                <b>{buildingData?.name}</b><br />
                                {buildingData?.address}<br />
                                {buildingData?.city}, {buildingData?.province} <br />
                                {buildingData?.country}&nbsp;&nbsp;{buildingData?.postalCode}<br />
                                <br />
                                <b>Timezone: </b>{buildingData?.timeZone}
                                <br />
                                <span className="text-small">
                                    <b>Created: </b>{new Date(buildingData?.creationDate).toDateString()}  by {buildingData?.creatorEmail}
                                </span>
                                <button type="button" className="btn btn-sm btn-secondary float-end" onClick={handleEditClick}>edit</button>
                            </div>
                        </div>
                    </div>
                    <div className="col col-12 col-lg-6 col-xl-4">
                        <div className="card">
                            <div className="card-title card-title-collapsable">
                                Assets
                            </div>
                            <div className="card-body scroll-y" style={{ maxHeight: "350px" }} >
                                <a href={`/assets/${buildingId}`}>View All Assets</a><br /><br />
                                <h4>Asset Types</h4>
                                {assetSummaryData?.assetTypes.map((item, index) => {
                                    return (<div key={index}>{item.type}: {item.quantity}<br /></div>);
                                })
                                }
                                <br/>
                                S-E-04 Required: {assetSummaryData?.se04count}<br/><br/>
                                <span className="text-small">Last Asset Created: {new Date(assetSummaryData?.lastCreationDate).toLocaleString()} by {assetSummaryData?.lastCreatorName}</span>
                            </div>
                        </div>
                    </div>
                    {/*<div className="col col-12 col-lg-6 col-xl-4">*/}
                    {/*    <div className="card">*/}
                    {/*        <div className="card-title card-title-collapsable">*/}
                    {/*            Site Inspections*/}
                    {/*        </div>*/}
                    {/*        <div className="card-body scroll-y" style={{ maxHeight: "350px" }} >*/}
                    {/*            <span>No inspections performed</span>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="col col-12 col-lg-6 col-xl-4">
                        <div className="card">
                            <div className="card-title card-title-collapsable">
                                Files
                            </div>
                            <div className="card-body scroll-y" style={{ maxHeight: "350px" }}>
                                <FileUpload itemId={buildingId} itemType="buildings" onComplete={requestFileData} />
                                {fileData.length > 0 ? (
                                    fileData.map((fileItem, idx) => {
                                        //get the appropriate icon if no file preview is available
                                        const fileExtension = fileItem.fileName.split('.').pop().toLowerCase();
                                        const icon = faIconMap[fileExtension] || faFile;

                                        return (
                                            <div className="row mt-1 mb-2 p-2 clickable hoverable rounded" onClick={() => downloadFile(fileItem)} key={fileItem.id}>
                                                <div className="col-1 clickable" >
                                                    {fileItem.filePreview ?
                                                        <img src={fileItem.filePreview ? `data:${fileItem.mimeType};base64,${fileItem.filePreview}` : undefined}
                                                            style={{ maxHeight: "40px", maxWidth: "40px" }}
                                                        />
                                                        :
                                                        <span className="fa-stack">
                                                            <i className="fa-solid fa-circle fa-stack-2x"></i>
                                                            <i className={`fas ${icon} fa-stack-1x fa-inverse`}></i>
                                                        </span>
                                                    }
                                                </div>
                                                <div className="col ms-2">
                                                    {fileItem.fileName}<br />
                                                    <span className="text-small">{fileItem.creatorName
                                                        ? `${new Date(fileItem.creationDate).toLocaleString()} by ${fileItem.creatorName}`
                                                        : new Date(fileItem.creationDate).toLocaleString()}
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                    })
                                ) : (
                                    <div className="text-center m-3">No files uploaded</div>
                                )}

                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="row">
                <div className="col">
                    <div className="card">
                        <div className="card-title card-title-collapsable">
                            Notes
                        </div>
                        <div className="card-body">
                            <div className="col col-12">
                                <textarea style={{ width: "100%" }} placeholder="Notes" value={noteText} onChange={ev => setNoteText(ev.target.value)} />
                                <button type="button" className="btn btn-sm btn-primary float-end mt-2" onClick={submitNotesData}>save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className="card">
                        <div className="card-title card-title-collapsable">
                            Audit Log
                        </div>
                        <div className="card-body">
                            <div className="col col-12">
                                <table className="table" style={{ minWidth: '650' }} aria-label="simple table">
                                        <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th>Notes</th>
                                                <th align="right">User</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {auditLogData.slice(0, 10).map((log, idx) => (
                                                <tr
                                                    key={idx}
                                                    style={{ border: 0 }}
                                                >
                                                    <td scope="row">
                                                        {new Date(log.creationDate).toLocaleString()}
                                                    </td>
                                                    <td>{log.changeText}</td>
                                                    <td align="right">{log.creatorEmail}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <CreateBuildingDialog isOpen={editDialogOpen} onSubmit={handleEditDialogSubmit} initialData={{ ...buildingData }} buildingSuccess={(successStatus) => setBuildingSuccess(successStatus)} />
            <AuditLogPopup isOpen={logPopupOpen} onClose={handleLogPopupClose} initialData={[...auditLogData]} />
        </LoadingTemplate>
       </>
    );
};

