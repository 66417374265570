import React, { useCallback, useEffect, useRef, useMemo, useState } from 'react'
import { useAuthFetch } from '../useAuthFetch';
import { LoadingTemplate } from '../LoadingTemplate';
import { getApiUrl } from '../authConfig';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { SideBarDef,} from "ag-grid-community";

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import "../assets/css/formItems.css"

export function TemplatesPage() {
    const authFetch = useAuthFetch();
    const inputRef = React.useRef(null);
    const gridRef = useRef<AgGridReact>(null);

    const [templateData, setTemplateData] = useState([]);
    const [templateItemId, setTemplateItemId] = useState("");
    const [isChecked, setIsChecked] = useState(true);
    const [modalDeleteOpen, setModalDeleteOpen] = useState(false); 

    useEffect(() => { requestTemplateData(isChecked); }, [isChecked])

    const handleCheck = (e) => { setIsChecked(e.target.checked);};

    const requestTemplateData = async (checked) => {
        setTemplateData([])
        let fetchResponse = await authFetch('GET', getApiUrl(`templates?latest=${(checked).toString()}`))

        if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
            let responseData = [];

            try {
                responseData = await fetchResponse.json();
            } catch (error) {
                console.log('Request Template Data Error: ' + error);
            } finally {
                setTemplateData(responseData);
            }
        }
    }

    const deleteTemplateConfirm = async (templateItem) => {
        setTemplateItemId(templateItem.id);
        setModalDeleteOpen(true);
    }

    const templateDeleteClick = async () => {
        let fetchResponse = await authFetch('DELETE', getApiUrl(`templates/${templateItemId}`))

        if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
            let responseData = [];

            try {
                responseData = await fetchResponse.json();
            } catch (error) {
                console.log('Delete Template Error:' + error);
            } finally {
                setModalDeleteOpen(false);
                setTemplateItemId("");
                requestTemplateData(isChecked);
            }
        } 
    }

    const downloadTemplate = async (templateItem) => {
        let fetchResponse = await authFetch('GET', getApiUrl(`templates/${templateItem.id}`)).then(response => response.blob())
            .then(blob => {
                const downloadUrl = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = downloadUrl;
                a.download = templateItem.templateId + ".json"; // Optional: Set a default file name
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(downloadUrl); // Cleanup
            })
            .catch(error => {
                console.error('Error downloading the inspection:', error);
            });
    };

    const handleClick = React.useCallback(() => inputRef.current?.click(), []);

    const handleSubmit = e => {
        const fileReader = new FileReader();
        fileReader.readAsText(e.target.files[0], "UTF-8");
        fileReader.onload = e => {
            uploadTemplateData(JSON.parse(e.target.result.toString()));
        }
    }

    const uploadTemplateData = async (templateBody) => {
        let fetchResponse = await authFetch('POST', getApiUrl("templates"), templateBody)

        if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
            let responseData = [];

            try {
                responseData = await fetchResponse.json();
            } catch (error) {
                console.log('Template Upload Error:' + error);
            } finally {
                requestTemplateData(isChecked);
            }
        }
    }

    const downloadButtonComponent = (props) => {
        return (
            <>
                <FontAwesomeIcon icon={faDownload} className="me-3" onClick={() => downloadTemplate({ ...props.data })} style={{ cursor: 'pointer' }} />
                <FontAwesomeIcon icon={faTrash} style={{ cursor: 'pointer' }} onClick={
                    () => deleteTemplateConfirm({ ...props.data })
                } />
            </>
        )
    };

    const [colDefs, setColDefs] = useState([
        { headerName: "Name", field: "name", maxWidth: 275 },
        { headerName: "Template ID", field: "templateId", maxWidth: 200 },
        { headerName: "Current Version", field: "version", maxWidth: 150 },
        { headerName: "Scope", field: "templateItemType", maxWidth: 150 },
        { headerName: "Created On", field: "creationDate", maxWidth: 200, valueFormatter: function (params) { return moment (params.value).format('M/DD/YYYY, h:mm:ss A') } },
        { headerName: "Created By", field: "creatorName", maxWidth: 150 },
        { headerName: "Actions", field: "Actions", maxWidth: 100, cellRenderer: downloadButtonComponent }
    ]);

    const onFilterTextBoxChanged = useCallback(() => {
        gridRef.current!.api.setGridOption(
            "quickFilterText",
            (document.getElementById("filter-text-box") as HTMLInputElement).value,
        );
    }, []);

    const statusBar = useMemo(() => {
        return {
            statusPanels: [
                { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
                { statusPanel: 'agFilteredRowCountComponent', align: 'left' }
            ]
        };
    }, []);

    const sideBar = useMemo<
        SideBarDef | string | string[] | boolean | null
    >(() => {
        return {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivots: true,
                        suppressPivotMode: true,
                        suppressColumnFilter: true,
                        suppressColumnSelectAll: true,
                        suppressColumnExpandAll: true,
                    },
                },
            ],
            defaultToolPanel: "",
        };
    }, []);

    const onCsvExport = useCallback(() => {
        gridRef.current!.api.exportDataAsCsv();
    }, []);

    const onExcelExport = useCallback(() => {
        gridRef.current!.api.exportDataAsExcel();
    }, []);

    return (
        <div style={{ width: "100%" }}>
            <div className="mb-3">
                <h1>Templates</h1>
                <label className="me-3">Current Version Only</label><input type="checkbox" checked={isChecked} onChange={handleCheck}  />
                    <form encType="multipart/form-data">
                        <input ref={inputRef} id="inputUpload" type="file" accept=".json" onChange={handleSubmit} style={{ display: "none" }} />
                        <Button type="button" className="btn btn-primary btn-sm" onClick={handleClick}>UPLOAD TEMPLATE&nbsp;&nbsp;<i className="fa fa-upload" /></Button>
                    </form>
            </div>

            <div className="row mb-2">
                <div className="col col-8 col-lg-3 col-sm-6">
                    <input type="text" id="filter-text-box" className="form-control" placeholder="Filter..." onInput={onFilterTextBoxChanged} />
                </div>
                <div style={{ float: "left", width: "8%" }} >
                    <i className="fa-solid fa-file-excel fa-2x me-2 clickable hoverable" onClick={onExcelExport} style={{ lineHeight: '1.25em' }}></i>
                    <i className="fa-solid fa-file-csv fa-2x clickable hoverable" onClick={onCsvExport}></i>
                </div>
            </div>

            <div className="ag-theme-quartz" style={{ height: 500, width: "100%" }}>
                <AgGridReact ref={gridRef} rowData={templateData} columnDefs={colDefs} sideBar={sideBar} statusBar={statusBar} />
            </div>

            <Modal className="modalStyle" show={modalDeleteOpen} centered>
                <Modal.Header>
                    <Modal.Title>Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this template?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={templateDeleteClick}>Delete</Button>
                    <Button variant="secondary" onClick={() => { setModalDeleteOpen(false); setTemplateItemId("") }}>Cancel</Button>
                </Modal.Footer>
            </Modal>

        </div>
    );
};

