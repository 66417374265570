import React, {useEffect, useState} from "react";
import { useAuthFetch }  from './useAuthFetch'
import { observer, useLocalObservable } from 'mobx-react';
import { toJS } from 'mobx';
import { minimalTimezoneSet } from 'compact-timezone-list';
import { getApiUrl } from "./authConfig";

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import "./assets/css/modal.css";
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';

import { BuildingStatuses } from "./common/typeOptions";
import { Country } from "./common/typeOptions";

import { GetState } from "react-country-state-city";

export type CreateBuildingDialogData = {
    id: string,
    name: string, 
    address: string, 
    city: string, 
    province: string, 
    country: string, 
    postalCode: string, 
    latitude: number,
    longitude: number, 
    status: string, 
    timeZone: string
}

export type CreateBuildingDialogProps = {
    isOpen?: boolean
    onSubmit?: (status: string)=>void
    initialData?: CreateBuildingDialogData
    buildingSuccess(successStatus: string): void
} 

export const CreateBuildingDialog = observer((props: CreateBuildingDialogProps)=> {
    const authFetch = useAuthFetch(); 
    const navigate = useNavigate();

    const [validated, setValidated] = useState(false);
    const [countryid, setCountryid] = useState(0);
    const [stateid, setStateid] = useState(0);

    const [stateList, setStateList] = useState([]);

    let isOpen = props.isOpen || false;
    let existingId = props.initialData == null ? "" : props.initialData.id;

    var initialData = props.initialData || {
        name: "", 
        address: "", 
        city: "", 
        province: "", 
        country: "", 
        postalCode: "", 
        latitude: 0,
        longitude: 0, 
        status: "Inactive", 
        timeZone: "Europe/London"
    }

    const formData = useLocalObservable(()=> ({
        name: initialData.name, 
        address: initialData.address, 
        city: initialData.city, 
        province: initialData.province, 
        country: initialData.country, 
        postalCode: initialData.postalCode, 
        latitude: initialData.latitude,
        longitude: initialData.longitude, 
        status: initialData.status, 
        timeZone:initialData.timeZone
        
    }))

    const formError = useLocalObservable(() => ({
        name: "",
        address: "",
        city: "",
        province: "",
        country: "",
        postalCode: ""

    }));

    const handleClose = () => {
        if(props.onSubmit != null) {
            props.onSubmit("cancel")
        }
    };

    const handleSubmit = async () => {
        let data = toJS(formData);
        let method = existingId == ""? "POST": "PUT"; 
        let url = getApiUrl("buildings"); 

        if(existingId != "") {
            url = `${url}/${existingId}`;
        }

        try {
            let fetchResponse = await authFetch(method, url, data);
            if (fetchResponse.status != 409) {
                if (props.onSubmit != null) {
                    const bObject = await fetchResponse.json();
                    props.onSubmit("success");
                    if (method === "POST") {
                        navigate(`/building/${bObject.id}`);
                    } else {
                        props.buildingSuccess("Building: " + bObject.name + " has been updated at " + new Date().toLocaleTimeString());
                    }
                }
            } else {
                //setModalOpen(true)
            }
        } catch (error) {
            console.log('error:' + error);
        }
    } 

    const handleSubmitForm = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            setValidated(false);
            handleSubmit();
            event.preventDefault();
        }
    };

    useEffect(() => {
        setCountryid(formData.country == "Canada" ? 39 : 233);
        GetState(formData.country == "Canada" ? 39 : 233).then((result) => {
            setStateList(result);
        });
    }, []);

    const countryChange = (event) => {
        formData.country = event.target.value;
        formData.province = "";
        formData.city = "";
        setCountryid(event.target.value == "Canada" ? 39 : 233);
        GetState(event.target.value == "Canada" ? 39 : 233).then((result) => {
            setStateList(result);
        });
    }

    const provinceChange = (event) => {
        const stateItem = stateList.find(x => x.name === event.target.value);
        formData.province = stateItem.name;
        formData.city = "";
        setStateid(stateItem.id);
    }

    const timeZoneChange = (event) => {
        formData.timeZone = event.target.value;
    }

    const statusChange = (event) => {
        formData.status = event.target.value;
    }

    const title = existingId == "" ? "Create Building" : "Update Building";

    return (
           
        <Modal show={isOpen} size="lg">
            <Form noValidate validated={validated} onSubmit={handleSubmitForm}>
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div style={{ marginBottom: '12px' }}>Please enter the information about the building</div>
                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                                <div className="dropdown-label">Name</div>
                            <Form.Control maxLength={64} className="modal-input" size="lg" type="text" placeholder={formData.name} value={formData.name} onChange={e => formData.name = e.target.value} />
                        </div>
                    </div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label">Country</div>
                        <Form.Select required className={"d-grid"} size="lg" value={formData.country} onChange={countryChange}>
                            <option value="" disabled></option>
                                {Country.map((countryItem, index) => {
                                return (<option key={index} value={countryItem.name}>{countryItem.name}</option>);
                            })}
                        </Form.Select>
                    </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">{formData.country === "USA" ? "State" : "Province"}</div>
                            <Form.Select required className={"d-grid"} size="lg" value={formData.province} onChange={provinceChange}>
                                <option value="" disabled></option>
                                {stateList.map((stateListItem, index) => {
                                    return (<option key={index} value={stateListItem.name}>{stateListItem.name}</option>);
                                })}
                            </Form.Select>
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">City</div>
                            <Form.Group controlId="validationCustom01">
                                <Form.Control required maxLength={64} className="modal-input" size="lg" type="text" value={formData.city} placeholder={formData.city} onChange={e => formData.city = e.target.value} />
                            </Form.Group>
                        </div>
                    </div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label">Address</div>
                        <Form.Group controlId="validationCustom01">
                                <Form.Control required maxLength={64} className="modal-input" size="lg" type="text" value={formData.address} placeholder={formData.address} onChange={e => formData.address = e.target.value} />
                        </Form.Group>
                    </div>
                </div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label">Postal Code</div>
                        <Form.Group controlId="validationCustom01">
                            <Form.Control required className="modal-input" size="lg" type="text" value={formData.postalCode} placeholder={formData.postalCode} onChange={e => formData.postalCode = e.target.value} />
                        </Form.Group>
                    </div>
                </div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                            <div className="dropdown-label">Latitude</div>
                            <Form.Control className="modal-input" max={90} min={-90} size="lg" type="number" value={formData.latitude} onChange={e => formData.latitude = parseFloat(e.target.value)} />
                    </div>
                </div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label">Longitude</div>
                            <Form.Control className="modal-input" max={180} min={-180} size="lg" type="number" value={formData.longitude} onChange={e => formData.longitude = parseFloat(e.target.value)} />
                    </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Status</div>
                            <Form.Select required className={"d-grid"} size="lg" value={formData.status} onChange={statusChange}>
                                <option value="" disabled></option>
                                {BuildingStatuses.map((status, index) => {
                                    return (<option key={index} value={status}>{status}</option>);
                                })}
                            </Form.Select>
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Time Zone</div>
                            <Form.Select required className={"d-grid"} size="lg" value={formData.timeZone} onChange={timeZoneChange}>
                                <option value="" disabled></option>
                                {minimalTimezoneSet.map((timeZone, index) => {
                                    return (<option key={index} value={timeZone.tzCode}>{timeZone.label}</option>);
                                })}
                            </Form.Select>
                        </div>
                    </div>

            </Modal.Body>

                <Modal.Footer>
                    <Button onClick={handleClose} className="btn btn-light">Cancel</Button>
                    <Button name="submitbtn" type="submit" id="submitButton" className="btn btn-light">Submit</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
})
