import { useState } from "react";
import { useAuthFetch } from '.././useAuthFetch'
import { getApiUrl } from '.././authConfig';

export async function requestProductData (authFetch: any) {
    let fetchResponse = await authFetch('GET', getApiUrl(`products`))
    if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
        let responseData = [];
        try {
            responseData = await fetchResponse.json();
        } catch (error) {
            console.log('Request product Data Error: ' + error);
        } finally {
            return responseData;
        }
    }
}

export async function getProductGroupByProductFamily(authFetch: any) {
    let fetchResponse = await authFetch('GET', getApiUrl(`products?orderByFamily=true`))
    if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
        let responseData = [];
        try {
            responseData = await fetchResponse.json();
        } catch (error) {
            console.log('Request product Data Error: ' + error);
        } finally {
            return responseData;
        }
    }
}

export async function getProductGroupByAssetTypesData(authFetch: any) {
    let fetchResponse = await authFetch('GET', getApiUrl(`products`))
    if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
        let responseData = [];
        try {
            responseData = await fetchResponse.json();
        } catch (error) {
            console.log('Request product Data Error: ' + error);
        } finally {
            let result = groupByKeys(responseData, ["assetType"]);
            return result;
        }
    }
}

const groupByKeys = (data, keys) => {
    let finalResult = keys.map(key => {
        return Object.values(data.reduce((result, obj) => {
            let objKey = obj.assetType
            result[objKey] = result[objKey] || { key: objKey, value: 0 };
            result[objKey].value += 1;
            return result
        }, {}))
    })
    return finalResult
}