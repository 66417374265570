import { jwtDecode } from 'jwt-decode';

export function getRoles() {
    if (localStorage.getItem("btkn") != null) {
        return jwtDecode(localStorage.getItem("btkn")).roles;
    } else {
        return null;
    }
}

export function roleAdmin(): boolean {
    let userRoles = getRoles();
    if (userRoles != undefined && userRoles.indexOf("Admin") > -1) {
        return true;
    } else if (userRoles != undefined && userRoles.indexOf("Dev") > -1) {
        return true;
    } else {
        return false;
    }
}

export function roleDev(): boolean {
    let userRoles = getRoles();
    if (roleAdmin()) {
        return true;
    } else if (userRoles != undefined && userRoles.indexOf("Dev") > -1) {
        return true;
    } else {
        return false;
    }
}

export function roleRoiSeniorTech(): boolean {
    let userRoles = getRoles();
    if (roleAdmin()) {
        return true;
    } else if (userRoles != undefined && userRoles.indexOf("SrTechnician") > -1) {
        return true;
    } else {
        return false;
    }
}

export function roleRoiQAManagerTech(): boolean {
    let userRoles = getRoles();
    if (roleAdmin()) {
        return true;
    } else if (userRoles != undefined && userRoles.indexOf("QaManager") > -1) {
        return true;
    } else {
        return false;
    }
}

export function roleRoiQAControllerTech(): boolean {
    let userRoles = getRoles();
    if (roleAdmin()) {
        return true;
    } else if (userRoles != undefined && userRoles.indexOf("QAController") > -1) {
        return true;
    } else {
        return false;
    }
}