import React, {useEffect, useRef, useState} from "react";
import { useAuthFetch }  from './useAuthFetch';
import { observer, useLocalObservable } from 'mobx-react';
import { toJS } from 'mobx';
import { getApiUrl } from "./authConfig";

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import "./assets/css/modal.css";
import Form from 'react-bootstrap/Form';

import { CommissioningItems } from "./common/typeOptions";

export type CreateProductDialogData = {
    id?: string,
    description: string,
    assetType: string,
    commissioning: string,
    model: string,
    manufacturer: string,
    productCode: string,
    productFamily: string,
    defaultTemplate: string
}

export type CreateProductDialogProps = {
    isOpen?: boolean
    onSubmit?: (status: string)=>void
    initialData?: CreateProductDialogData
    productFamilies?: Array<any>
    defaultTemplates?: Array<any>
    productSuccess(successStatus: string): void
} 

export const CreateProductDialog = observer((props: CreateProductDialogProps) => {
    const myForm = useRef();
    const authFetch = useAuthFetch(); 
    const [ modalOpen, setModalOpen ] = useState(false); 

    let isOpen = props.isOpen || false;
    let existingId = props.initialData == null? "": props.initialData.id;

    const productFamilies = props.productFamilies || [];
    const defaultTemplates = props.defaultTemplates || [];
    const [validated, setValidated] = useState(false);

    const handleModalErrorClose = () => { setModalOpen(false); };

    const initialData = props.initialData || {
        description: "", 
        assetType: "Water", 
        commissioning: "No Commissioning", 
        model: "",
        manufacturer: "", 
        productCode: "", 
        productFamily: "",
        defaultTemplate: ""
    }

    const formData = useLocalObservable(()=> ({
        description: "", 
        assetType: "Water",  
        commissioning: "No Commissioning", 
        model: "", 
        manufacturer: "",
        productCode: "", 
        productFamily: "",
        defaultTemplate: ""
    }))


    const setInitialData = ()=> {
        formData.description =  initialData.description;
        formData.commissioning = initialData.commissioning;
        formData.model =  initialData.model;
        formData.manufacturer =  initialData.manufacturer;
        formData.productCode = initialData.productCode;
        formData.defaultTemplate = initialData.defaultTemplate;

        if (initialData.productFamily == undefined) {
            formData.productFamily = "";
            formData.assetType = "";
        } else {
            formData.productFamily = initialData.productFamily;
            formData.assetType = initialData.assetType;
        }
    }

    useEffect(()=> {setInitialData()}, [props.initialData]);

    const handleClose = () => {
        if(props.onSubmit != null) {
            props.onSubmit("cancel")
        }
    };

    const handleSubmit = async ()=> {
        let data = toJS(formData);

        let method = "POST"; 
        let url = getApiUrl("products"); 
        if (existingId != undefined) {
            url = `${url}/${existingId}`;
            method = "PUT";
        }

        let fetchResponse = await authFetch(method, url, {...data})
        if (fetchResponse.status != 409) {
            const bObject = await fetchResponse.json();
            if(props.onSubmit != null) {
                if (method === "PUT") {
                    props.productSuccess("Product: " + bObject.description +" has been updated at " + new Date().toLocaleTimeString());
                } else {
                    props.productSuccess("Product: " + bObject.description +" has been added at " + new Date().toLocaleTimeString());
                }
                props.onSubmit("success");
            }
        } else {
            setModalOpen(true)
        }
    }

    const handleSubmitForm = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            setValidated(false);
            handleSubmit();
            event.preventDefault();
        }
    };

    const templateChange = (event) => {
        formData.defaultTemplate = event.target.value;
    }

    const commissioningChange = (event) => {
        formData.commissioning = event.target.value;
    }

    const familyChange = (event) => {
        const selectedProdFamily = productFamilies.find(item => item.id === event.target.value);
        formData.productFamily = selectedProdFamily.id;
        formData.assetType = selectedProdFamily.category;
    }

    const title = existingId == ""? "Create Product": "Update Product";

    return (

        <Modal show={isOpen} size="lg">
            <Form noValidate validated={validated} onSubmit={handleSubmitForm} ref={myForm}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div style={{ marginBottom: '12px' }}>Please enter the information about the product</div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                            <div className="dropdown-label">Description</div>
                            <Form.Group controlId="validationCustom01">
                                <Form.Control required className="modal-input" size="lg" type="text" placeholder={formData.description} value={formData.description} onChange={e => formData.description = e.target.value} />
                            </Form.Group>
                    </div>
                </div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label">Product Family</div>
                        <Form.Select required className={"d-grid"} size="lg" value={formData.productFamily} onChange={familyChange}>
                            <option key={0} value="" selected disabled></option>
                            {productFamilies.map((family, index) => {
                                return (<option key={index} value={family.id}>{family.name}</option>);
                            })}
                        </Form.Select>
                    </div>
                </div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label-disabled">Asset Type</div>
                        <Form.Control className="modal-input-disabled" size="lg" type="text" placeholder={formData.assetType} aria-label="Disabled input example" readOnly />
                    </div>
                    </div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label">Commissioning</div>
                        <Form.Select className={"d-grid"} size="lg" value={formData.commissioning} onChange={commissioningChange}>
                            <option value="" disabled></option>
                                {CommissioningItems.map((commission, index) => {
                                return (<option key={index} value={commission}>{commission}</option>);
                            })}
                        </Form.Select>
                    </div>
                </div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label">Model</div>
                            <Form.Control required className="modal-input" size="lg" type="text" placeholder={formData.model} value={formData.model} onChange={e => formData.model = e.target.value} />
                    </div>
                </div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label">Manufacturer</div>
                            <Form.Control required className="modal-input" size="lg" type="text" placeholder={formData.manufacturer} value={formData.manufacturer} onChange={e => formData.manufacturer = e.target.value} />
                    </div>
                </div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label">Product Code</div>
                            <Form.Control required className="modal-input" size="lg" type="text" placeholder={formData.productCode} value={formData.productCode} onChange={e => formData.productCode = e.target.value} />
                    </div>
                </div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label">Default Templates</div>
                        <Form.Select className={"d-grid"} size="lg" value={formData.defaultTemplate} onChange={templateChange}>
                            <option value="" disabled></option>
                            {defaultTemplates.map((status, index) => {
                                return (<option key={index} value={status.name}>{status.name}</option>);
                            })}
                        </Form.Select>
                    </div>
                </div>

            </Modal.Body>

            <Modal.Footer>
                <Button onClick={handleClose} className="btn btn-light">
                    Cancel
                </Button>
                <button className="btn btn-light">
                    Submit
                </button>
            </Modal.Footer>

            <Modal className="modalStyle" show={modalOpen} centered>
                <Modal.Header>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>The machine with the given manufacturer, product code and serial number already exists.</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalErrorClose}>Close</Button>
                </Modal.Footer>
            </Modal>
            </Form>
        </Modal>
    );
});
