import React from "react";

const RoiMetering: React.FunctionComponent =() => {
    return (
        <div className="roi-body-header">
            <div className="roi-body-header-text">Meter Point Information</div>
        </div>
        )
}

export default RoiMetering