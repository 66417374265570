import React, { Children, ReactNode } from 'react'

type LoadingInspectionProps = {
    isLoading: boolean
    fallback?: ReactNode
    children?: ReactNode
}

export function LoadingInspection(props: LoadingInspectionProps) {

    if(props.isLoading) {

        if(props.fallback == null) {
            return (
                <div style={{ display: "flex", margin: "0 auto", height: "50px" }}>
                    <div style={{ marginTop: "10px" }} className="spinner-border text-primary" role="status"></div>
                    <div style={{ fontSize: "20px", marginLeft: "5px", lineHeight: "50px", height: "50px" }}>Loading Inspections</div>
                </div>
            )
        }

        return props.fallback; 
    }

    return props.children; 
}
