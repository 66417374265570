import { useState } from "react";
import { useAuthFetch } from '.././useAuthFetch'
import { getApiUrl } from '.././authConfig';

export async function requestInspectionData(authFetch: any, assetId: any) {
    let fetchResponse = await authFetch('GET', getApiUrl(`inspections/assets/${assetId}`))
    if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
        let responseData = [];
        try {
            responseData = await fetchResponse.json();
            responseData = responseData.reverse();
        } catch (error) {
            console.log('Request Inspection Data Error: ' + error);
        } finally {
            return responseData;
        }
    }
}

export async function requestInspectionDataTemplates (authFetch: any, assetId: any) {
    let fetchResponse = await authFetch('GET', getApiUrl(`inspections/assets/${assetId}?template=true`))
    if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
        let responseData = [];
        try {
            responseData = await fetchResponse.json();
            responseData = responseData.reverse();
        } catch (error) {
            console.log('Request Inspection Data Error: ' + error);
        } finally {
            return responseData[0];
        }
    }
}

export async function requestInspectionDataLast (authFetch: any, inspectionId: any) {
    let fetchResponse = await authFetch('GET', getApiUrl(`inspections/${inspectionId}?template=true&photoSize=240`))
    if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
        let responseData = [];
        try {
            responseData = await fetchResponse.json();
        } catch (error) {
            console.log('Request Inspection Data Error: ' + error);
        } finally {
            return responseData;
        }
    }
}